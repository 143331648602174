import { Box, BoxProps } from '@chakra-ui/core'
import { rem } from 'design'
import {
  Body,
  H1,
  H2,
  LinkExternal,
  LinkInternal,
} from 'design/components/Typography/Typography'
import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import useProtectedPage from '~/cms/hooks/useProtectedPage'
import UnauthenticatedLayout from '~/layouts/UnauthenticatedLayout'
import * as Styles from '~/styles/ccpa-privacy-policy.css'
import { cleanJsonStrings } from '~/utils/unicode'
import '../Legal.css'

export default function CCPAPrivacyPolicy() {
  const { pageData } = cleanJsonStrings(
    useStaticQuery<{ pageData: any }>(graphql`
      query {
        pageData: contentfulProtectedPage(
          pageId: { eq: "ccpa-privacy-policy" }
        ) {
          ...ProtectedPage
        }
      }
    `)
  )

  const { header } = useProtectedPage(pageData)

  return (
    <UnauthenticatedLayout
      className="legalPage"
      cta={header?.cta?.content}
      margin="auto"
      maxW={{ base: '100%', desktop: '120ch' }}
      px={rem(46)}
      py={rem(36)}
      title="California Privacy Policy"
    >
      <div className={Styles.ccpaLayoutDiv}>
        <H1 fontWeight={700} id="top">
          California Privacy Policy
        </H1>
        <Body>Last Revised on: August 31, 2022</Body>
        <br />
        <Body>
          This Privacy Policy for California Residents (“California Privacy
          Policy”) supplements the information contained in ButcherBox OpCo,
          LLC’s primary privacy{' '}
          <LinkInternal href="/privacy-policy">
            https://www.butcherbox.com/privacy-policy/
          </LinkInternal>
          . ButcherBox OpCo, LLC (“ButcherBox,” “we,” “us,” or “our”) has
          adopted this California Privacy Policy in accordance with the notice
          requirements under the California Consumer Privacy Act of 2018, as
          amended (“CCPA”), and any terms defined in the CCPA have the same
          meaning when used in this California Privacy Policy.
        </Body>
        <Body>
          This California Privacy Policy applies only to information collected
          through: the following websites we own:{' '}
          <LinkInternal href="/">www.butcherbox.com</LinkInternal> and
          information collected through other channels, such as over the phone.
        </Body>
        <br />
        <Section>
          <H2>Definitions Specific to this Policy</H2>
          <Body>
            The CCPA includes definitions for terms specific to this California
            Privacy Policy that do not apply to ButcherBox’s privacy policy,
            including the following terms:
          </Body>
          <Body>
            “<u>Personal Information</u>” means information that identifies,
            relates to, describes, is reasonably capable of being associated
            with, or could reasonably be linked, directly or indirectly, with a
            particular consumer or household. Personal Information does not
            include publicly available information obtained from government
            records; deidentified or aggregated consumer information that can
            not be reconstructed to identify you; any information covered under
            the Gramm-Leach-Bliley Act or the California Financial
            InformationPrivacy Act, activities covered by the Fair Credit
            Reporting Act, or protected health information as defined under the
            Health Insurance Portability and Accountability Act.
          </Body>
          <Body>
            “<u>Sale</u>” or “<u>sell</u>” means selling, renting, releasing,
            disclosing, disseminating, making available, transferring, or
            otherwise communicating orally, in writing, or by electronic or
            other means, a consumer’s Personal Information by the business to
            another business or a third party for monetary or other valuable
            consideration.
          </Body>
          <Body>
            “<u>Service Provider</u>” means a sole proprietorship, partnership,
            limited liability company, corporation, association, or other legal
            entity that is organized or operated for the profit or financial
            benefit of its shareholders or other owners, that processes
            information on behalf of a business and to which the business
            discloses a consumer’s Personal Information for a business purpose
            pursuant to a written contract.
          </Body>
          <Body>
            “<u>Third Party</u>” means a person or entity who is not a business
            that collects Personal Information from consumers, as defined in the
            CCPA, and to whom the business discloses a consumer’s Personal
            Information for a business purpose pursuant to a written contract.
          </Body>
        </Section>
      </div>
      <Section>
        <div className={Styles.ccpaLayoutDiv}>
          <H2 id="collectionNotice">
            The Personal Information We Collect and Disclose
          </H2>
          <Body>
            The chart below shows the categories of Personal Information we
            collect or may collect; examples of the type of Personal Information
            in each category; all types of sources from which each category
            ofPersonal Information is collected; the business purpose(s) for
            which that category of Personal Information is collected; and all
            types of third parties with whom that category of Personal
            Information is shared. As this chart shows, we disclose certain
            Personal Information to third parties for a business purpose. We
            also sell certain Personal Information to third parties. We do not
            sell the Personal Information of minors under 16 years old without
            affirmative authorization. This information covers the past 12
            months.
          </Body>
        </div>
        <div className={Styles.ccpaTableParent}>
          <table
            aria-label="Personal information type and usage table"
            className={Styles.ccpaTable}
            role="table"
            // eslint-disable-next-line
            tabIndex={0}
          >
            <thead>
              <tr>
                <th className={Styles.ccpaHeaderCell} scope="col">
                  Categories of Personal Information
                </th>
                <th className={Styles.ccpaHeaderCell} scope="col">
                  Examples:
                </th>
                <th className={Styles.ccpaHeaderCell} scope="col">
                  Types of Sources from which this Category of Personal
                  Information Is Collected
                </th>
                <th className={Styles.ccpaHeaderCell} scope="col">
                  Business Purpose(s) for Collecting, Disclosing, and Selling
                </th>
                <th className={Styles.ccpaHeaderCell} scope="col">
                  Types of Third Parties with Whom this Category of Personal
                  Information Is Sold and/or Disclosed
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Unique identifiers
                </th>
                <td className={Styles.ccpaBodyCell}>
                  Name, signature, address, telephone email, account name, DL
                  number, passport number, online identifier, IP address
                </td>
                <td className={Styles.ccpaBodyCell}>
                  The consumer directly;
                  <br />
                  <br />
                  Marketing systems (cookies, pixels, IP, etc.);
                  <br />
                  <br />
                  Shipping partners;
                  <br />
                  <br />
                  Fulfillment partners; and
                  <br />
                  <br />
                  Credit reporting agencies.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Customer support;
                  <br />
                  <br />
                  Credit card processing;
                  <br />
                  <br />
                  Digital marketing (including the creation of custom targeted
                  audiences);
                  <br />
                  <br />
                  Social media advertising;
                  <br />
                  <br />
                  Shipping/ order fulfillment; and
                  <br />
                  <br />
                  Order processing.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Sold:</strong> Data analytics tools;
                  <br />
                  <br />
                  Digital marketing ; and
                  <br />
                  <br />
                  Social media companies and users.
                  <br />
                  <br />
                  <strong>Disclosure: </strong>Carriers and shipping partners;
                  <br />
                  <br />
                  Ticketing Platforms;
                  <br />
                  <br />
                  Email service providers;
                  <br />
                  <br />
                  Customer service providers; and
                  <br />
                  <br />
                  Payment providers.
                </td>
              </tr>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Physical description
                </th>
                <td className={Styles.ccpaBodyCell}>
                  Physical characteristics or description
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Customers (if disclosed in free text forms).
                </td>
                <td className={Styles.ccpaBodyCell}>
                  To tailor customer experience if you opt in.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Disclosure: </strong>
                  Parties with access to system where the customer disclosed
                  this information; and
                  <br />
                  Customer service providers.
                </td>
              </tr>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Financial information
                </th>
                <td className={Styles.ccpaBodyCell}>
                  Bank account number, credit card number, debit card number, or
                  any other financial information
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Directly from customers;
                  <br />
                  <br />
                  Partners of the business; and
                  <br />
                  <br />
                  Credit reporting agencies.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  {' '}
                  Complete payment transactions (with customers or partners);
                  and
                  <br />
                  <br />
                  Process returns.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Disclosure:</strong> Payment processors;
                  <br />
                  <br />
                  Shipment and fulfillment providers; and
                  <br />
                  <br />
                  Customer service providers.
                </td>
              </tr>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Protected class data
                </th>
                <td className={Styles.ccpaBodyCell}>
                  Characteristics of protected classifications under California
                  or federal law, including: race, ancestry, national origin,
                  age (40 years or older), marital status, sex, sexual
                  orientation, medical condition, disability, genetics, and
                  veteran or military status
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Directly from Customers;
                  <br />
                  <br />
                  Credit report agencies; and
                  <br />
                  <br />
                  Social media companies.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Customer service; and
                  <br />
                  <br />
                  To provide relevant digital marketing.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Sold:</strong> Data analytics tools;
                  <br />
                  <br />
                  Digital marketing; and
                  <br />
                  <br />
                  Social media companies and users.
                  <br />
                  <br />
                  <strong>Disclosure: </strong>Marketing partners; and
                  <br />
                  <br />
                  Customer service providers.
                </td>
              </tr>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Purchase history
                </th>
                <td className={Styles.ccpaBodyCell}>
                  Commercial information, including records of personal
                  property, products or services purchased, obtained, or
                  considered, or other purchasing or consuming histories or
                  tendencies
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Directly from Customers;
                  <br />
                  Credit reporting agencies; and
                  <br />
                  Digital marketing.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Customer service;
                  <br />
                  Experience management; and
                  <br />
                  Digital marketing (including the creation of custom targeted
                  audiences).
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Sold:</strong> Analytics companies; and
                  <br />
                  Digital marketing.
                  <br />
                  Disclosure: Payment processors;
                  <br />
                  <br />
                  Shipping and fulfillment providers; and
                  <br />
                  <br />
                  Customer service providers.
                </td>
              </tr>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Biometric data
                </th>
                <td className={Styles.ccpaBodyCell}>Voice recordings</td>
                <td className={Styles.ccpaBodyCell}>
                  From Customer; and
                  <br />
                  <br />
                  Our call center providers.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  To record customer service calls.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Disclosure:</strong> Customer service providers.
                </td>
              </tr>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Internet activity
                </th>
                <td className={Styles.ccpaBodyCell}>
                  Internet or other electronic network activity information,
                  including, but not limited to, browsing history, search
                  history, and information regarding a consumer’s interaction
                  with an Internet Web site, application, or advertisement
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Directly from customers;
                  <br />
                  <br />
                  Social media companies and users; and
                  <br />
                  <br />
                  Digital Marketing and analytics tools.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Customer support/experience; and
                  <br />
                  <br />
                  Digital marketing and advertising (including the creation of
                  custom targeted audiences).
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Sold:</strong> Data analytics tools;
                  <br />
                  <br />
                  Digital marketing; and
                  <br />
                  <br />
                  Social media companies and users.
                  <br />
                  <br />
                  <strong>Disclosure: </strong>
                  Email providers
                </td>
              </tr>
              <tr>
                <th className={Styles.ccpaBodyCell} scope="row">
                  Inferences
                </th>
                <td className={Styles.ccpaBodyCell}>
                  Inferences drawn from any of the information identified in
                  this subdivision to create a profile about a consumer
                  reflecting the consumer’s preferences, characteristics,
                  psychological trends, predispositions, behavior, attitudes,
                  intelligence, abilities, and aptitudes
                </td>
                <td className={Styles.ccpaBodyCell}>
                  The consumer directly;
                  <br />
                  <br />
                  Digital marketing companies;
                  <br />
                  <br />
                  Social media companies;
                  <br />
                  <br />
                  Shipping partners;
                  <br />
                  <br />
                  Fulfillment partners; and
                  <br />
                  <br />
                  Credit reporting agencies.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  Digital marketing (including the creation of custom targeted
                  audiences);
                  <br />
                  <br />
                  Email marketing; and
                  <br />
                  <br />
                  Customer support/experience.
                </td>
                <td className={Styles.ccpaBodyCell}>
                  <strong>Sold:</strong> Data analytics tools;
                  <br />
                  <br />
                  Digital marketing; and
                  <br />
                  <br />
                  Social media companies and users.
                  <br />
                  <br />
                  <strong>Disclosure:</strong> Email providers.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className={Styles.ccpaLayoutDiv}>
          <Body>
            We will not collect a category of Personal Information not listed
            above, or use any Personal Information collected in any of the above
            categories for a business purpose not listed above, without first
            providing you with notice.
          </Body>

          <Body>
            You have the right to request certain information regarding
            thePersonal Information we have collected about you in the preceding
            12 months. You may make such a request up to twice in a 12-month
            span. Please note that there are circumstances in which we may not
            be able to comply with your request pursuant to the CCPA, including
            when we cannot verify your request and/or when there is a conflict
            with our own obligations to comply with other legal or regulatory
            requirements.
          </Body>

          <List>
            <ListItem>
              If you would like to request (1) the categories of Personal
              Information collected about you; (2) the categories of sources
              providing that Personal Information; (3) the categories of third
              parties with whom we have shared that Personal Information; and(4)
              the business purpose for collecting that Personal Information,{' '}
              <CcpaLink>click here</CcpaLink> or call 855-744-6309. The link
              will take you to an interactive webform that you can complete and
              submit to make a request for information that we will then verify;
              if you use the telephone number, you will be guided through a
              process that will allow you to submit a verifiable request.
            </ListItem>
            <ListItem>
              If you would like to request the specific pieces of Personal
              Information we collected about you,{' '}
              <CcpaLink>click here</CcpaLink> or call 855-744-6309. The link
              will take you to an interactive webform that you can complete and
              submit to make a request for information that we will then verify;
              if you use the telephone number, you will be guided through a
              process that will allow you to submit a verifiable request.
            </ListItem>
            <ListItem>
              If you would like to request (1) the categories of Personal
              Information collected about you; (2) the categories of Personal
              Information sold; (3) the categories of third parties to whom the
              information was sold; and (4) the categories of Personal
              Information that were disclosed for a business purpose,{' '}
              <CcpaLink>click here</CcpaLink> or call 855-744-6309. The link
              will take you to an interactive webform that you can complete and
              submit to make a request for information that we will then verify;
              if you use the telephone number, you will be guided through a
              process that will allow you to submit a verifiable request.
            </ListItem>
          </List>
        </div>
      </Section>
      <div className={Styles.ccpaLayoutDiv}>
        <Section>
          <H2>Verification Process</H2>
          <Body>
            In order to protect your information, we will confirm a requestor’s
            identity before responding to any request. We will ask a few
            questions in order to confirm your identity. To complete the
            verification process you must correctly answer one or more questions
            to confirm identity. If you do not receive a follow up email please
            contact us at{' '}
            <EmailLink email="consumerinformation@butcherbox.com" /> or
            855-744-6309. Otherwise, we will acknowledge your request and
            contact you later with our full response.
          </Body>
          <Body>
            We will only send CCPA verification questions from{' '}
            <EmailLink email="privacy@butcherbox.com" />. Before sending a
            verification response, please double-check the email address. If you
            receive an email that appears to be from Us but is not from our CCPA
            verification email address listed above, do not respond.
          </Body>
          <Body>
            Any information we gather through this process will only be used for
            consumer verification, security procedures, or fraud-prevention. We
            will delete any verification information as soon as practical after
            processing your request, unless required by law.
          </Body>
        </Section>

        <Section>
          <H2>Non-Discrimination; Financial Incentives</H2>
          <Body>
            We will not discriminate against you if you exercise any of the
            rights provided by the CCPA set forth in this California Privacy
            Policy. Please note that a legitimate denial of a request for
            information, deletion, or to opt-out is not discriminatory, nor is
            charging a fee for excessive or repetitive consumer requests as
            permitted by the CCPA.
          </Body>
          <Body>
            We offer a member benefits program (“Member Benefits Program”) as a
            financial incentive to consumers who allow us to retain and share
            their Personal Information. These incentives generally include
            complimentary or discounted products, and other special offers. For
            more information on our Member Benefits Program log in to your
            account. Additionally, in exercising your rights under this
            California Privacy Policy, if you choose to delete your data or opt
            out of its sale, such an election will effectively prevent access to
            your Member Benefits Program and/or your participation in our
            MemberBenefits Program. Therefore, we unfortunately must treat such
            an election as a withdrawal from our Member Benefits Program, and
            your account for the Member Benefits Program will be terminated.
          </Body>
          <Body>
            We estimate the average value of a Member Benefits Program is $75
            per year (which may be higher or lower depending on when you join).
            We arrived at such value by estimating the approximate retail value
            of complimentary or discounted products provided through our Member
            Benefits Program.
          </Body>
        </Section>

        <Section>
          <H2>Deletion of Your Personal Information</H2>
          <Body>
            You have the right to request that we delete certain Personal
            Information that we have collected. Please note that there are
            circumstances in which we may not be able to comply with your
            request pursuant to the CCPA, including when we cannot verify your
            request and/or when there is a conflict with our own obligations to
            comply with other legal or regulatory requirements. We will notify
            you following submission of your request if this is the case.
          </Body>
          <List>
            <ListItem>
              If you would like to request that we delete your Personal
              Information pursuant to the requirements of the CCPA, click{' '}
              <CcpaLink>here</CcpaLink> or call 855-744-6309. The link will take
              you to an interactive form that you can complete and submit to
              make a request for deletion that we will then verify; if you use
              the telephone number, you will be guided through a process that
              will allow you to submit a verifiable request.
            </ListItem>
          </List>
        </Section>

        <Section>
          <H2>Authorized Agents</H2>
          <Body>
            You may designate an authorized agent to exercise your rights under
            the CCPA on your behalf.
          </Body>

          <Body>Pursuant to the CCPA:</Body>
          <List>
            <ListItem>
              Only a business entity or natural person registered with the
              California Secretary of State may act as an authorized agent.
            </ListItem>
            <ListItem>
              You must provide the authorized agent written permission to
              exercise your rights under the CCPA on your behalf.
            </ListItem>
            <ListItem>
              We may deny a request from an authorized agent on your behalf if
              the authorized agent does not submit proof that he, she, or it has
              been authorized by you to act on your behalf if we request such
              proof, as permitted by the CCPA.
            </ListItem>
            <ListItem>
              Even if you use an authorized agent to exercise your rights under
              the CCPA on your behalf, pursuant to the CCPA we may still require
              that you verify your own identity directly to us. This Provision
              does not apply if you have provided with a power of attorney under
              California Probate Code sections 4000 to 4465.
            </ListItem>
          </List>
        </Section>

        <Section>
          <H2>Personal Information of Minors</H2>
          <Body>
            Our websites and mobile applications are not intended for children
            or minors under the age of sixteen (16) years. Accordingly, we do
            not knowingly store information from minors under the age of sixteen
            years. If you believe that a child has submitted Personal
            Information to Us, please contact us at{' '}
            <EmailLink email="consumerinformation@butcherbox.com" /> or
            855-744-6309.
          </Body>
        </Section>

        <Section>
          <H2>Personal Information of Minors</H2>
          <Body>
            Our websites and mobile applications are not intended for children
            or minors under the age of sixteen (16) years. Accordingly, we do
            not knowingly store information from minors under the age of sixteen
            years. If you believe that a child has submitted Personal
            Information to Us, please contact us at{' '}
            <EmailLink email="consumerinformation@butcherbox.com" /> or
            855-744-6309.
          </Body>
        </Section>

        <Section>
          <H2 id="optOut">Right to Opt-Out</H2>
          <Body>
            You have the right to request that we do not sell Personal
            Information that we have collected. If you exercise that
            right,please note that pursuant to the CCPA we may have to
            subsequently inform you that you need to opt-in if the sale of your
            Personal Information is required to complete a transaction you have
            initiated. If opting-in is required, we will provide you with the
            necessary instructions.
          </Body>

          <List>
            <ListItem>
              If you would like to request that we do not sell your Personal
              Information pursuant to the requirements of the CCPA,{' '}
              <CcpaLink>click here</CcpaLink> or call 855-744-6309. The link
              will take you to an interactive form that you can complete and
              submit to make a request for deletion; if you use the telephone
              number, you will be guided through a process that will allow you
              to submit a request.
            </ListItem>
            <ListItem>
              You may also submit a request to opt-out by emailing{' '}
              <EmailLink email="consumerinformation@butcherbox.com" />
            </ListItem>
            <ListItem>
              If you use an authorized agent to submit your request to opt-out,
              we will request that the agent provide us with proof that he, she,
              or it has been authorized by you to act on your behalf. The CCPA
              requires you to provide the authorized agent written permission to
              exercise your rights under the CCPA.
            </ListItem>
          </List>

          <Body>
            If you opt-out of the sale of your Personal Information, we will
            wait at least 12 months before asking you if we may sell your
            Personal Information.
          </Body>

          <Body>
            You have the right to opt-in to the sale of your Personal
            Information after you have opted out. If you would like to opt-in to
            the sale of your Personal Information, contact us at{' '}
            <EmailLink email="consumerinformation@butcherbox.com" /> or
            855-744-6309. Opting-in is a two-step process in which you will
            first clearly request to opt-in, and then separately confirm that
            choice.
          </Body>
        </Section>

        <Section>
          <H2>Additional Information</H2>
          <Body>
            If you would like additional information regarding our California
            Privacy Policy, please contact us at{' '}
            <EmailLink email="consumerinformation@butcherbox.com" /> or
            855-744-6309.
          </Body>
        </Section>

        <Section>
          <H2>Additional California Privacy Rights</H2>
          <Body>
            California Civil Code Section § 1798.83 permits users of our website
            that are California residents to request certain information
            regarding our disclosure of Personal Information to other parties
            for their direct marketing purposes. To make such a request, please
            send an email to{' '}
            <EmailLink email="consumerinformation@butcherbox.com" /> with the
            subject “Shine the Light Request.”
          </Body>
          <Body>
            You may view our main privacy policy{' '}
            <LinkInternal href="/privacy-policy">here</LinkInternal> or contact
            us at <EmailLink email="consumerinformation@butcherbox.com" /> or
            855-744-6309 with any questions.
          </Body>
        </Section>

        <Section>
          <H2>Changes to Our California Privacy Policy</H2>
          <Body>
            ButcherBox reserves the right to amend this California Privacy
            Policy at our discretion and at any time. When we make changes to
            this California Privacy Policy, we will post an updated notice on
            our website with the effective date.
          </Body>
          <Body>
            Click <LinkInternal href="#top">here</LinkInternal> to go back to
            start of California Privacy Policy.
          </Body>
        </Section>
      </div>
    </UnauthenticatedLayout>
  )
}

const EmailLink = ({ email, ...props }) => (
  <LinkExternal href={`mailto:${email}`} target="_self" {...props}>
    {email}
  </LinkExternal>
)

const CcpaLink = ({ children }) => (
  <LinkExternal href="https://privacyportal.onetrust.com/webform/013493b4-8b22-4d55-8091-22ecc186b7b4/31b1fb21-bdbf-4df7-a13e-a8f2363ef8f3">
    {children}
  </LinkExternal>
)

const Section = (props: BoxProps) => (
  <Box as="section" css={{ '> *': { marginBottom: rem(24) } }} {...props} />
)

const List = (props: BoxProps) => (
  <Box
    as="ul"
    css={{ '> *': { marginBottom: rem(16) } }}
    ml={rem(16)}
    my={rem(16)}
    {...props}
  />
)

const ListItem = (props: BoxProps) => <Body as="li" {...props} />
